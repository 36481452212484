import { rr } from "lib/reverseRouter";
import Link from "next/link";
import styles from "./Footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <>
      <section className={styles.social_container}>
        <div className={styles.social_inner}>
          <h3>最新情報を配信中</h3>
          <ul className={styles.social_list}>
            <li>
              <a
                href="https://manga-no.com/info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/logo_black_cutter.svg"
                  alt="マンガノブログ"
                  className={styles.blog_icon}
                />
                <span>マンガノブログ</span>
              </a>
            </li>
            <li>
              <a
                href="https://x.com/mangano_pr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/images/ic_x.svg"
                  alt="X"
                  className={styles.sns_icon}
                />
                <span>公式SNS</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <footer className={styles.footer_container}>
        <div className={styles.footer_inner}>
          <div className={styles.message_wrapper}>
            <div className={styles.message_box}>
              <h3>はてなと集英社で作りました</h3>
              <p>
                はてなブログなどでユーザー投稿サービスに携わってきたはてなと、
                <br />
                マンガに長年携わってきた集英社が一緒にゼロからサービスを作りました。
              </p>
              <p>
                安心してマンガ投稿と販売を開始していただける仕組みを２社で真剣に考えました。ぜひご利用ください。
              </p>
            </div>
            <ul className={styles.logo_box}>
              <li>
                <a
                  href="https://hatena.co.jp/"
                  className={styles.hatena_logo_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/logo_hatena.svg"
                    alt="はてな"
                    className={styles.hatena_logo}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.shueisha.co.jp/"
                  className={styles.shueisha_logo_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/logo_shueisha.svg"
                    alt="集英社"
                    className={styles.shueisha_logo}
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.navi_wrapper}>
            <Link {...rr.index()} className={styles.mangano_logo_link}>
              <img
                src="/images/logo_white_cutter.svg"
                alt="マンガノ"
                className={styles.mangano_logo}
              />
            </Link>
            <nav>
              <ul className={styles.navi_list}>
                <li>
                  <a href="/info/help">ヘルプ</a>
                </li>
                <li>
                  <a href="/info/guideline">ガイドライン</a>
                </li>
                <li>
                  <a
                    href="https://forms.gle/spT2gFeTHSbc6eUg9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    フィードバック
                  </a>
                </li>
                <li>
                  <a href="/info/tos">利用規約</a>
                </li>
                <li>
                  <a href="/info/privacy">プライバシーポリシー</a>
                </li>
                <li>
                  <a
                    href="https://hatena.co.jp/tokutei"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    特定商取引法の表示
                  </a>
                </li>
                <li>
                  <a href="/info/userdata-external-transmission">
                    利用者情報の外部送信について
                  </a>
                </li>
              </ul>
            </nav>
            <address>&copy; Hatena Co., Ltd.</address>
          </div>
        </div>
      </footer>
    </>
  );
};
