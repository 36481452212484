import { MouseEventHandler } from "react";
import styles from "./SiteReadMoreButton.module.scss";

interface SiteReadMoreButtonProps {
  onClick: MouseEventHandler;
}

export const SiteReadMoreButton: React.FC<SiteReadMoreButtonProps> = ({
  onClick,
}) => {
  return (
    <button onClick={onClick} className={styles.more}>
      <span>もっと見る</span>
    </button>
  );
};
