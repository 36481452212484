import classNames from "classnames";
import styles from "./LoadingIndicator.module.scss";

type LoadingIndicatorProps = {
  invert?: boolean;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  invert = false,
}) => (
  <div className={styles.wrapper}>
    <span className={styles.loading}>
      <span
        className={classNames(styles.dot1, { [styles.invert]: invert })}
      ></span>
      <span
        className={classNames(styles.dot2, { [styles.invert]: invert })}
      ></span>
      <span
        className={classNames(styles.dot3, { [styles.invert]: invert })}
      ></span>
    </span>
  </div>
);
