import { gql } from "@apollo/client";
import classNames from "classnames";
import { Logo } from "components/elements/Logo";
import { useGetNotificationUnreadCountQuery } from "generated/graphql";
import { getUserOrSignInAnonymously } from "lib/firebase";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import { useAsync } from "react-use";
import styles from "./SettingHeader.module.scss";

gql`
  query GetNotificationUnreadCount($first: Int, $after: String) {
    viewer {
      ... on RegisteredViewer {
        notifications(first: $first, after: $after) {
          unreadCount
        }
      }
    }
  }
`;

interface SettingHeaderProps {
  type?: "workspace" | "my_page" | "history" | "top" | "notification";
}

// 未読数の最大
const UNREAD_COUNT_MAX = 99;

// TODO(post-release): もう設定関連の画面だけに出るものではないのでコンポーネント名を検討する
export const SettingHeader: React.FC<SettingHeaderProps> = ({ type }) => {
  const { data } = useGetNotificationUnreadCountQuery({
    fetchPolicy: "cache-and-network",
  });
  const { value: user } = useAsync(getUserOrSignInAnonymously);
  const className = classNames(styles.header, {
    [styles.workspace]: type === "workspace",
    [styles.my_page]: type === "my_page",
    [styles.history]: type === "history",
    [styles.top]: type === "top",
    [styles.notification]: type === "notification",
  });

  const unreadCount =
    data?.viewer.__typename === "RegisteredViewer"
      ? Math.min(data.viewer.notifications.unreadCount, UNREAD_COUNT_MAX)
      : 0;

  return (
    <header className={className}>
      <div className={styles.header_wrapper}>
        <div className={styles.logo_wrapper}>
          <h1 className={styles.logo_container}>
            <a href="/">
              <Logo />
            </a>
          </h1>
          <p className={styles.catch}>
            すべてのマンガ家のための、新しい発信基地。
          </p>
        </div>
        <ul className={styles.navi}>
          {user ? (
            user.isAnonymous ? (
              <AnonymousUserHeader />
            ) : (
              <LoggedInUserHeaderWithNotification unreadCount={unreadCount} />
            )
          ) : null}
          <li>
            <Link {...rr.search.index({})} className={styles.search_button}>
              <span>検索</span>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

const AnonymousUserHeader: React.FC = () => (
  <>
    <li>
      <Link {...rr.creator.index()}>
        <span>マンガノとは</span>
      </Link>
    </li>
    <li>
      <Link {...rr.signin.index({})}>
        <span>ログイン</span>
      </Link>
    </li>
    <li className={classNames(styles.signup, styles.separate)}>
      <Link {...rr.signup.index({})}>
        <span>会員登録</span>
      </Link>
    </li>
    <li>
      <Link {...rr.readingHistories.index()} className={styles.history_button}>
        <span>履歴</span>
      </Link>
    </li>
  </>
);

// const LoggedInUserHeader: React.FC = () => (
//   <>
//     <li>
//       <Link {...rr.workspace.index()}>
//         <a className={styles.workspace_button}>
//           <span>ワークスペース</span>
//         </a>
//       </Link>
//     </li>
//     <li className={styles.separate}>
//       <Link {...rr.my.index()}>
//         <a className={styles.my_page_button}>
//           <span>マイページ</span>
//         </a>
//       </Link>
//     </li>
//   </>
// );

type LoggedInUserHeaderWithNotificationProps = Readonly<{
  unreadCount: number;
}>;
const LoggedInUserHeaderWithNotification: React.FC<
  LoggedInUserHeaderWithNotificationProps
> = ({ unreadCount }) => (
  <>
    <li>
      <Link {...rr.workspace.index()} className={styles.workspace_button}>
        <span>ワークスペース</span>
      </Link>
    </li>
    <li>
      <Link {...rr.my.index()} className={styles.my_page_button}>
        <span>マイページ</span>
      </Link>
    </li>
    <li className={styles.separate}>
      <Link
        {...rr.notifications.index()}
        className={styles.notification_button}
      >
        <span>通知</span>
        {unreadCount > 0 && (
          <span className={styles.unread_count}>{unreadCount}</span>
        )}
      </Link>
    </li>
  </>
);
