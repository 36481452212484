import { createContext, ReactNode, useContext, useMemo } from "react";

type GACampaignParamsContext = {
  utmSource?: string;
  utmContent?: string;
  utmCampaign?: string;
};

const GACampaignParamsContext = createContext<
  GACampaignParamsContext | undefined
>(undefined);

type GACampaignParamsProviderProps = {
  utmSource?: string;
  utmContent?: string;
  utmCampaign?: string;
  children: ReactNode;
};

export const GACampaignParamsProvider: React.FC<
  GACampaignParamsProviderProps
> = ({ utmSource, utmContent, utmCampaign, children }) => {
  const gaCampaignParamsContext = useMemo<GACampaignParamsContext>(() => {
    return {
      utmSource,
      utmContent,
      utmCampaign,
    };
  }, [utmSource, utmContent, utmCampaign]);

  return (
    <GACampaignParamsContext.Provider value={gaCampaignParamsContext}>
      {children}
    </GACampaignParamsContext.Provider>
  );
};

export type GACampaignQueries = {
  utm_source: string;
  utm_content?: string;
  utm_campaign?: string;
};

export const useGACampaignQueries = (): GACampaignQueries | undefined => {
  const gaCampaignParamsContext = useContext(GACampaignParamsContext);

  if (!gaCampaignParamsContext) {
    return undefined;
  }

  const { utmSource, utmContent, utmCampaign } = gaCampaignParamsContext;
  return {
    utm_source: utmSource ? utmSource : "manga-no",
    utm_content: utmContent,
    utm_campaign: utmCampaign,
  };
};
