import { convertTemplateUrl } from "lib/image";
import React from "react";

export type ScissorsImageProps = {
  templateUrl: string;
  width: number;
  height: number;
} & Omit<React.ComponentProps<"img">, "src">;

export const ScissorsImage = React.forwardRef<
  HTMLImageElement,
  ScissorsImageProps
>(({ templateUrl, width, height, style, ...rest }, ref) => {
  const src = convertTemplateUrl(templateUrl, width, height);
  return (
    <img
      ref={ref}
      {...rest}
      src={src}
      width={width}
      height={height}
      style={{ objectFit: "cover", ...style }}
    />
  );
});

ScissorsImage.displayName = "ScissorsImage";
