import styles from "./Logo.module.scss";

export const Logo: React.FC = () => (
  <span className={styles.wrapper}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.81 211.79">
      <title>マンガノ</title>
      <path
        className={styles.logo}
        d="M156.67,14.8c-21,11.7-28.23-1.75-61.39-2.44C36.73,11.13-1.24,57.47,0,110.93c-1.06,50.94,41.2,91.93,95.2,91.93,41.29,0,75.29-39.87,103.78-3,6,8.21,12.42,11.91,22.48,11.91,9.39,0,15.12-4.61,20.31-9.92,6.71-6.85,8.27-12.68,16.42-17.1C378.11,123.47,275.88-51.62,156.67,14.8ZM80.86,117.24l4.28,5.14-7.63,6.35L62.89,111.17l7.64-6.35,3.92,4.71,13-8.07L85.83,96H52.07V86H93.22l6,19.85ZM109.79,86h17.73V96H109.79Zm32.53,29c-8.49,9-20,12.46-30,13.49v-10c9.75-1.15,17.38-4.6,22.74-10.32A29,29,0,0,0,142.7,91h10A39.12,39.12,0,0,1,142.32,115Zm55.8-36.67h6V88.51h-6Zm5.27,43.27-7.82,5.87-9.47.94v-10l5.73-.57,2.31-1.74,2-13.82-2.54-3.81h-7.5a50.74,50.74,0,0,1-4.82,15.72c-4,7.65-10,12.52-18,14.56V118.35a17.86,17.86,0,0,0,9.16-8.72A39.62,39.62,0,0,0,176,98.5H163.3V88.57h13.82c.14-2.41.21-5,.21-7.7h9.93c0,2.71-.06,5.27-.19,7.7h11.82L206.48,100Zm10.29-33.06h-6V78.37h6ZM246,114c-7.44,8-16.86,13.18-26.45,14.53V118.48c6.86-1.31,13.64-5.24,19.15-11.21a42.55,42.55,0,0,0,11-23.79h10A52.44,52.44,0,0,1,246,114Z"
      />
    </svg>
  </span>
);
