import { Image2 } from "types/images";

export function convertTemplateUrl(
  url: string,
  width: number,
  height: number
): string {
  let convertedUrl = url;
  convertedUrl = convertedUrl.replace("{width}", String(width));
  convertedUrl = convertedUrl.replace("{height}", String(height));
  return convertedUrl;
}

type Size = { width: number; height: number };
type SizeKey = `width:${number}, height:${number}`;
function createKey(from: Size): SizeKey {
  return `width:${from.width}, height:${from.height}` as const;
}

function isWithinRange(value: number, min: number, max: number): boolean {
  return value >= min && value <= max;
}

// 基準サイズからの許容しきい値
const STANDARD_SIZE_ADJUST_MIN = 0.9;
const STANDARD_SIZE_ADJUST_MAX = 1.1;

/**
 * 引数の画像の中から最も多く出てくる SizeKey (縦横サイズ) のものを基準サイズとする
 * 基準サイズから縦、横のサイズから上下10%以内のサイズであれば許容する
 * これから外れた Image2 の Id は checkIllegalSizeImageIds の返り値の Set に含まれて返される
 * これを「サイズ違いの画像」として表示側で扱う
 * @param targets Image2[]
 */
export function checkIllegalSizeImageIds(targets: Image2[]): Set<string> {
  if (targets.length < 2) {
    return new Set<string>();
  }

  let mostFrequentSizeKeyCount = 0;
  const sizeCountMap = new Map<SizeKey, number>(); // SizeKeyに対する出現回数Map
  const sizeKeyAndSizeMap = new Map<SizeKey, Size>(); // SizeKeyに対するSize型Map
  targets.forEach((image) => {
    const sizeKey = createKey(image);
    const sizeKeyCount = (sizeCountMap.get(sizeKey) ?? 0) + 1;
    sizeCountMap.set(sizeKey, sizeKeyCount);
    sizeKeyAndSizeMap.set(sizeKey, {
      width: image.width,
      height: image.height,
    });
    mostFrequentSizeKeyCount = Math.max(mostFrequentSizeKeyCount, sizeKeyCount);
  });

  // mostFrequentSize に達している（最頻出のSizeである） SizeKey だけを抜き出す。
  // 複数の SizeKey が同じカウントで含まれているかもしれないが、その場合、最もエピソードのはじめに現れたページ画像のサイズを優先する仕様としたい。
  // javascript は Map の key 挿入順が保証されているので、Map のイテレータを順に回していき、条件にヒットすれば直ちにそれを対象 key とできる。
  const mostFrequentSizeKey: SizeKey | undefined = (() => {
    for (const [key, count] of sizeCountMap.entries()) {
      if (mostFrequentSizeKeyCount === count) {
        return key;
      }
    }
    return undefined;
  })();

  // 基準サイズの許容しきい値を出して、その範囲外のものを返す
  const standardSize: Size = mostFrequentSizeKey
    ? sizeKeyAndSizeMap.get(mostFrequentSizeKey) || { width: 0, height: 0 }
    : { width: 0, height: 0 };
  return targets.reduce((set, image) => {
    // 基準サイズと同じ、もしくは許容しきい値内でサイズ違いな場合
    if (
      isWithinRange(
        image.width,
        standardSize.width * STANDARD_SIZE_ADJUST_MIN,
        standardSize.width * STANDARD_SIZE_ADJUST_MAX
      ) &&
      isWithinRange(
        image.height,
        standardSize.height * STANDARD_SIZE_ADJUST_MIN,
        standardSize.height * STANDARD_SIZE_ADJUST_MAX
      )
    ) {
      return set;
    }

    // 許容しきい値外でサイズ違い
    return set.add(image.id);
  }, new Set<string>());
}
