import dayjs from "dayjs";

// Day.js のフォーマットについては: https://day.js.org/docs/en/display/format

/**
 * Dateを MM/DD 形式の文字列にして返す。
 * @param date 文字列に変換するDate
 * @returns 07/07 のような日付の文字列
 */
export function formatDateShort(date: string): string {
  return dayjs(date).tz().format("MM/DD");
}

/**
 * Dateを YYYY/MM/DD 形式の文字列にして返す。
 * @param date 文字列に変換するDate
 * @returns 2020/07/07 のような日付の文字列
 */
export function formatDateMedium(date: string): string {
  return dayjs(date).tz().format("YYYY/MM/DD");
}

/**
 * Dateを YYYY/MM/DD(ddd) HH:mm 形式の文字列にして返す。
 * @param date 文字列に変換するDate
 * @returns 2020/07/07(火) 19:07 のような日付の文字列
 */
export function formatDateLong(date: string): string {
  return dayjs(date).tz().format("YYYY/MM/DD(ddd) HH:mm");
}

/**
 * Dateをtime要素のdatetime属性にセット用の文字列にして返す。
 * ユーザーへの表示用途ではない。
 *
 * @param date 文字列に変換するDate
 * @returns 妥当な datetime 値
 * @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/time
 */
export function formatDateForTimeElement(date: string): string {
  return dayjs(date).tz().format("YYYY-MM-DD");
}

/**
 * Dateを YYYY-MM-DD 形式の文字列にして返す。
 * ユーザーへの表示用途ではなく、`<input type="date">`のvalue属性に使うもの。
 *
 * @param date 文字列に変換するDate
 * @returns 2020-07-07 のような日付の文字列
 * @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/input/date
 */
export function formatDateForInputTypeDate(date: string): string {
  return dayjs(date).tz().format("YYYY-MM-DD");
}

/**
 * Dateと現在日時を比較し、dayNumber以内の差分であるかどうかを返す
 * TODO: formatter.tsの名前以上のことをやっているのでファイル名の整理を検討する
 *
 * @param date 現在日時と比較するDate
 * @param dayNumber この数字以下の日数差分であればtrueを返すという数字
 * @returns true/false
 */
export function dateIsWithinNDays(date: string, dayNumber: number): boolean {
  const now = dayjs().tz();
  const targetDate = dayjs(date).tz();
  return now.diff(targetDate, "day", true) < dayNumber;
}
