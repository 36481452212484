import { getBaseUrl } from "lib/url";
import NextHead from "next/head";
import { useRouter } from "next/router";
import React from "react";

export interface HeadProps {
  title: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogType?: string;
  ogImage?: string;
  canonical?: string;
}

export const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
  ogTitle,
  ogType,
  ogDescription,
  ogImage,
  canonical,
}) => {
  const router = useRouter();
  const baseUrl = getBaseUrl();

  const url = new URL(router.asPath, baseUrl);
  const ogUrl = url.toString();
  const ogImageUrl = new URL(
    ogImage ?? "/images/og_image_site.png",
    baseUrl
  ).toString();

  const pathname = new URL(url.pathname, baseUrl).toString();

  const ogDescriptionNorm = ogDescription?.replace(/[\r?\n]+/g, " ");
  const descriptionNorm = description?.replace(/[\r?\n]+/g, " ");

  return (
    <NextHead>
      <title>{title}</title>
      {descriptionNorm && <meta name="description" content={descriptionNorm} />}
      <meta property="og:title" content={ogTitle ?? title} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={ogType ?? "article"} />
      <meta property="og:image" content={ogImageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@mangano_pr" />
      {ogDescriptionNorm && (
        <meta property="og:description" content={ogDescriptionNorm} />
      )}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, viewport-fit=cover"
      />
      {/* クエリパラメータ無しの URL で正規化したいかどうかはページによって異なるため、外から渡せるようにもする */}
      <link rel="canonical" href={canonical ?? pathname} />
    </NextHead>
  );
};
