/**
 * 基底 URL を返す。
 * ISR 時でも返せるようにサーバーサイドでは環境変数に埋め込まれた値を返す。
 */
export function getBaseUrl(): string {
  // 手元や CI で Docker を介さず next build する際は BASE_URL が undefined になりビルドが落ちる。
  // 対策として BASE_URL が undefined の時は localhost を baseUrl とする
  return typeof window !== "undefined"
    ? window.location.origin
    : process.env.BASE_URL ?? "http://localhost:3000";
}
